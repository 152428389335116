import React from 'react';
import { Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal, ButtonGroup } from 'react-bootstrap';

import {
  Button,
  Heading,
  ButtonIcon,
  CustomTableWithAutoSettings,
} from 'components';
import { restoreApprovalsModalColumns } from 'services/tableshapes/index';
import Paragraph from 'components/atoms/Paragraph/index';

function RestoreApprovalsModal(props) {
  const { data, toggleModal, handleAction, loading, toPeriod } = props;

  return (
    <Modal show onEscapeKeyDown={() => toggleModal(false)}>
      <Modal.Header>
        <Heading level={3} style={{ margin: 0 }}>
          <Trans>Restore Approvals for Periods 1—{toPeriod}</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <Paragraph kind="primary" alignment="justify">
          <Trans>
            Approvals are typically automatically revoked if Accountflow detects
            that there were changes in a given period. If you believe these
            revocations were made by mistake, you can restore them from here.
          </Trans>
        </Paragraph>
        <CustomTableWithAutoSettings
          data={data}
          loading={loading}
          columns={restoreApprovalsModalColumns()}
          defaultSortBy="name"
          tableId="restore-approvals-modal"
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          <Button
            magnify
            onClick={() => handleAction('showAlert')}
            disabled={loading || !data.length}
          >
            <Trans>Restore Approvals</Trans>
          </Button>
          <Button fill kind="danger" magnify onClick={() => toggleModal(false)}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default RestoreApprovalsModal;
