import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import styled from 'styled-components';
import { CgCheckR } from '@react-icons/all-files/cg/CgCheckR';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { FaFilePdf } from '@react-icons/all-files/fa/FaFilePdf';
import { MdOutlineCancel } from '@react-icons/all-files/md/MdOutlineCancel';
import { AiFillFilePdf } from '@react-icons/all-files/ai/AiFillFilePdf';
import { FaTrashRestore } from '@react-icons/all-files/fa/FaTrashRestore';
import { Prompt } from 'react-router-dom';

import CardPDFTitle from 'containers/CardPDFTitle';
import {
  Field,
  ButtonIcon,
  GenerateReportButton,
  CustomTableWithAutoSettings,
} from 'components';

import { accountReconciliationColumns } from 'services/tableshapes';
import { accountReconciliationFilters } from 'services/tableFilterShapes';
import { generateUniqueFilterOptions } from 'services/helpers';
import {
  filterByApproveStatuses,
  filterByTemplateStatuses,
} from 'services/shapes';

function AccountReconciliationTable(props) {
  const {
    data,
    search,
    filters,
    loading,
    roleType,
    setFilters,
    vatEnabled,
    getRowColor,
    resetFilters,
    handleAction,
    assetsEnabled,
    showDeactived,
    isRowDisabled,
    selectedItems,
    payrollEnabled,
    selectCondition,
    actionSubmitting,
    handleCheckboxChange,
  } = props;

  const title = t`Account Reconciliation`;

  const blockedLines = selectedItems.filter(isRowDisabled);

  const approveTooltip =
    blockedLines.length > 0 &&
    t`Lines you have selected contain ${blockedLines.length} which
      cannot be approved. Please attach file, fill in dialog or unselect the
      next accounts: ${blockedLines.map((item) => item.accountId).join(', ')}`;

  const sortBy = useMemo(
    () => [
      {
        id: 'accountId',
        desc: false,
      },
    ],
    [],
  );
  const globalFilter = useMemo(
    () => ({
      approved: filterByApproveStatuses().filter(
        (item) =>
          item.id === (roleType?.isReadOnly ? 'approved' : 'unApproved'),
      ),
      accountTemplateCode: filterByTemplateStatuses().filter((item) =>
        ['BANK', 'GENERAL', 'SIMPLE_LEDGER'].includes(item.id),
      ),
    }),
    [],
  );

  const customFilters = useMemo(
    () => ({
      accountHolder: generateUniqueFilterOptions(data, 'accountHolder'),
    }),
    [JSON.stringify(data)],
  );

  const urlFilters = useMemo(() => {
    const _filters = {};

    if (search.filterByApprove === 'all') {
      _filters.approved = filterByApproveStatuses();
    }
    if (search.filterByTemplate) {
      const filterByTemplate = filterByTemplateStatuses().filter(
        (item) => item.id === search.filterByTemplate,
      );
      if (filterByTemplate.length) {
        _filters.accountTemplateCode = filterByTemplate;
      }
    }

    return _filters;
  }, [search.filterByApprove, search.filterByTemplate]);

  return (
    <div id="pdfToPrint" data-testid="pdfToPrint-accountReconciliation">
      <Prompt
        when={Object.values(actionSubmitting).some((value) => value)}
        // eslint-disable-next-line max-len
        message={t`If you leave this page, you will lose some of your work. Are you sure you want to leave?`}
      />

      <CardPDFTitle defaultNoTitle title={title} />
      <FieldHolder>
        <Field
          horizontal
          id="showDeactivated"
          type="checkbox"
          name="showDeactivated"
          label={t`Show deactivated accounts`}
          checked={showDeactived}
          onChange={handleCheckboxChange}
        />
        <GenerateReportButton
          options="ALL"
          withVersions
          versionsMeta={{ reportType: 'REPORT_PACKAGE' }}
          filters={filters}
          text={t`Generate Complete Report Package`}
          setFilters={setFilters}
          disabled={loading}
          resetFilters={resetFilters}
          pdfGenerating={actionSubmitting.generatePackage}
          pdfDownloading={actionSubmitting.downloadLastPackage}
          generatePDF={() => handleAction('generatePackage')}
          downloadLast={() => handleAction('downloadLastPackage')}
        />
      </FieldHolder>
      <CustomTableWithAutoSettings
        {...{
          data,
          loading,
          urlFilters,
          handleAction,
          actionSubmitting,
          columns: accountReconciliationColumns(roleType.isReadOnly),
          getRowColor,
          customFilters,
          tableId: 'accountReconciliation',
          loadingId: 'accountId',
          withRowClick: true,
          filterConfigs: accountReconciliationFilters(customFilters),
          headerConfigs: {
            exportFileName: title,
            extraButtons: [
              {
                id: 'generatePDF',
                title: t`Generate PDF Report`,
                icon: <ButtonIcon as={FaFilePdf} type="extraButton" />,
                buttonProps: {
                  size: 'sm',
                  kind: 'default',
                  fill: false,
                  magnify: true,
                  disabled: loading,
                },
              },
              {
                id: 'restoreApprovals',
                title: t`Restore Approvals`,
                icon: <ButtonIcon as={FaTrashRestore} type="extraButton" />,
                buttonProps: {
                  size: 'sm',
                  kind: 'danger',
                  fill: true,
                  magnify: true,
                  disabled: loading,
                },
              },
              // ...(!roleType.isReadOnly
              //   ? [
              //       {
              //         id: 'approveZero',
              //         title: t`Approve Accounts With No Movements`,
              //         buttonProps: {
              //           size: 'sm',
              //           kind: 'default',
              //           fill: false,
              //           magnify: true,
              //         },
              //       },
              //     ]
              //   : []),
            ],
          },
          selectConfigs: {
            selectCondition,
            dropdownsWithCheckboxes: [
              {
                id: 'generateMultipleReport',
                icon: <ButtonIcon as={AiFillFilePdf} marginRight={5} />,
                title: t`Generate PDF Report`,
                checkboxes: [
                  { id: 'withOverview', label: t`Include Account Overview` },
                  {
                    id: 'withVAT',
                    label: t`Include VAT`,
                    disabled: !vatEnabled,
                  },
                  {
                    id: 'withPayroll',
                    label: t`Include Payroll`,
                    disabled: !payrollEnabled,
                  },
                  {
                    id: 'withAssets',
                    label: t`Include Assets`,
                    disabled: !assetsEnabled,
                  },
                  {
                    id: 'withTrialBalance',
                    label: t`Include Trial Balance`,
                  },
                ],
              },
            ],
            buttons: [
              {
                id: 'control',
                icon: <ButtonIcon as={CgCheckR} type="selectConfig" />,
                title: t`Control`,
                buttonProps: {
                  size: 'sm',
                  kind: 'info',
                  fill: true,
                  magnify: true,
                },
              },
              {
                id: 'approve',
                icon: <ButtonIcon as={HiCheck} type="selectConfig" />,
                title: t`Approve`,
                tooltip: approveTooltip,
                buttonProps: {
                  size: 'sm',
                  kind: 'success',
                  fill: true,
                  magnify: true,
                  disabled: !!blockedLines.length,
                },
              },
              {
                id: 'unApprove',
                icon: <ButtonIcon as={MdOutlineCancel} type="selectConfig" />,
                title: t`Unapprove`,
                buttonProps: {
                  size: 'sm',
                  kind: 'danger',
                  fill: true,
                  magnify: true,
                },
              },
            ],
          },
          initialState: {
            sortBy,
            globalFilter,
          },
        }}
      />
    </div>
  );
}

AccountReconciliationTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  loading: PropTypes.bool,
  failed: PropTypes.bool,
};

AccountReconciliationTable.defaultProps = {
  data: [],
  failed: false,
  loading: true,
};

export default AccountReconciliationTable;

const FieldHolder = styled.div`
  position: absolute;
  top: -20px;
  right: 20px;
  z-index: 4;
  display: flex;
  align-items: center;
  gap: 10px;
`;
