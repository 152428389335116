import { t } from '@lingui/macro';

export const columns = () => [
  //   {
  //     width: 70,
  //     accessor: 'selection',
  //     className: 'flex-center',
  //     disableSortBy: true,
  //     cell: {
  //       type: 'selection',
  //       disableClick: true,
  //     },
  //   },
  {
    width: 70,
    Header: t`Account ID`,
    accessor: 'accountId',
    className: '',
  },
  {
    width: 200,
    Header: t`Description`,
    accessor: 'accountDescription',
    className: '',
    withTooltip: true,
  },
  {
    width: 70,
    Header: t`Period`,
    accessor: 'restoredPeriod',
    className: '',
    withTooltip: true,
  },
];

export default { columns };
