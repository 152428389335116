import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { pending, rejected } from 'redux-saga-thunk';
import { t, Trans } from '@lingui/macro';

import { RestoreApprovalsModal, AnimateAlert } from 'components';

import { restoreApprovalsApi } from 'services/apihelpers';
import { fromCompany, fromResource } from 'store/selectors';
import { resourceCreateRequest, resourceListReadRequest } from 'store/actions';
import getErrorMessage from 'services/helpers/getErrorMessage';
import { useParams } from 'react-router-dom';

function RestoreApprovalsModalContainer(props) {
  const { closeModal, refetchParent } = props;
  const { accountingPeriod, accountingYear } = useParams();
  const [actionSubmitting, setActionSubmitting] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const company = useSelector(fromCompany.getCompany);
  const dispatch = useDispatch();
  const query = {
    companySid: company.currentCompanySID,
    fromPeriod: 1,
    toPeriod: +accountingPeriod,
    year: accountingYear,
  };

  const data = useSelector((state) =>
    fromResource.getList(state, restoreApprovalsApi),
  );
  const failed = useSelector((state) =>
    rejected(state, `${restoreApprovalsApi}ListRead`),
  );
  const loading = useSelector((state) =>
    pending(state, `${restoreApprovalsApi}ListRead`),
  );

  const fetchData = () => {
    dispatch(resourceListReadRequest(restoreApprovalsApi, query));
  };

  const _closeModal = (refetch = false) => {
    closeModal();
    if (refetch === true && typeof refetchParent === 'function') {
      setTimeout(() => {
        refetchParent();
      }, 1000);
    }
  };

  const handleRestoreApprovals = async () => {
    try {
      await dispatch(resourceCreateRequest(restoreApprovalsApi, query));
      _closeModal(true);
    } catch (error) {
      toast.error('Error restoring approvals:', error);
    }
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleAction = async (action) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'doRestoreApprovals': {
          await handleRestoreApprovals();
          break;
        }

        case 'showAlert': {
          setShowAlert(true);
          break;
        }

        case 'hideAlert': {
          setShowAlert(false);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(fetchData, []);

  return (
    <>
      <AnimateAlert
        {...{
          showCancel: true,
          show: showAlert,
          type: 'warning',
          title: t`Warning`,
          onCancel: () => {
            handleAction('hideAlert');
          },
          onConfirm: () => {
            handleAction('doRestoreApprovals');
            handleAction('hideAlert');
          },
        }}
      >
        <Trans>
          You&apos;re about to restore {data.length} approvals that were
          previously cancelled. Approvals are typically automatically cancelled
          if Accountflow detects that there were changes in a given period.
        </Trans>
      </AnimateAlert>
      <RestoreApprovalsModal
        {...{
          data,
          failed,
          loading,
          handleAction,
          actionSubmitting,
          toPeriod: accountingPeriod,
        }}
        toggleModal={_closeModal}
      />
    </>
  );
}

export default RestoreApprovalsModalContainer;
